<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product/commodity' }">商品列表</el-breadcrumb-item>
                <el-breadcrumb-item>商品详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: 20px;"></div>
        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>商品详情</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :label-width="this.env.label_width">
                        <el-form-item label="商品名称">
                            <span>{{ info.title }}</span>
                        </el-form-item>
                        <el-form-item label="适用门店">
                            <span>{{ info.store_name }}</span>
                            <el-tag :key="item.index" v-for="item in info.store_uuid_all" class="form-tag_colour-tag"
                                type="info" size="mini">
                                {{ item.store_name }}
                            </el-tag>
                        </el-form-item>
                        <el-form-item label="运动品类">
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in info.motion_category_two"
                                    class="form-tag_colour-tag" effect="dark">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="运动效果">
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in info.target_category_zero"
                                    class="form-tag_colour-tag" effect="dark">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="商品视频">
                            <Qnupload v-model="info.video" type="video" :readonly='true' />
                            <span v-if="info.video_carousel === 1" class="form-span-text">视频放到轮播图</span>
                        </el-form-item>
                        <el-form-item label="商品封面" prop="cover">
                            <el-image style="width: 120px; height: 90px;" :src="info.cover" fit="contain"
                                :preview-src-list="[info.cover]">
                            </el-image>
                            <br>
                            <span class="form-tip">图片尺寸1024*1024 或 宽:高=1:1，用于商品卡片封面展示。</span>
                        </el-form-item>
                        <el-form-item label="商品照片" prop="photo">
                            <el-image v-for="(item, index) in info.photo" :key="index"
                                style="width: 120px; height: 90px; margin-right: 4px;" :src="item" fit="contain"
                                :preview-src-list="info.photo">
                            </el-image>
                            <br>
                            <span class="form-tip">图片尺寸 宽:高≈25:28，用于详情页轮播图展示。</span>
                        </el-form-item>
                        <el-form-item v-if="returnSkuState()" label="规格信息">
                            <section class="specs__table">
                                <div class="specs__table-header">
                                    <section class="specs__table-title">
                                        <p>规格标题</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>价格（元）</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>人数上限</p>
                                    </section>
                                </div>
                                <div class="specs__table-content">
                                    <section v-for="(item, index) in sku_data" :key="index">
                                        <div v-if="item.sku_name && item.sku_state === 1" class="specs__table-cell">
                                            <div class="specs__table-title">
                                                <p>{{ item.sku_name }}</p>
                                            </div>
                                            <div class="specs__table-title">
                                                <span class="form-span-text">{{ item.sku_price }}</span>
                                            </div>
                                            <div class="specs__table-title">
                                                <span class="form-span-text">{{ item.sku_qty }}</span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>
                        </el-form-item>
                        <el-form-item label="商品原价￥">
                            <span v-if="returnSkuState()" class="form-span-text">{{ minPrice }}</span>
                            <span v-else class="form-span-text">{{ info.originally_price }}</span>
                        </el-form-item>
                        <el-form-item v-if="!returnSkuState()" label="优惠价格">
                            <span>{{ info.promotion_price }}</span>
                        </el-form-item>
                        <el-form-item label="时长/分钟">
                            <span>{{ info.duration }}</span>
                        </el-form-item>

                    </el-form>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <h3>购买须知</h3>
                </el-col>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :label-width="this.env.label_width">
                        <el-form-item label="有效期至">
                            <span>{{ info.expiration_time }}</span>
                        </el-form-item>
                        <el-form-item label="使用时间">
                            <span>{{ info.usage_time }}</span>
                        </el-form-item>
                        <el-form-item label="预约信息">
                            <span>提前 {{ info.advance_booking }}天预约</span>
                        </el-form-item>
                        <el-form-item label="最多可购买">
                            <span> {{ info.fraction_limit }}份</span>
                        </el-form-item>
                        <el-form-item label="库存数量">
                            <span> {{ info.stock }}</span>
                        </el-form-item>
                        <el-form-item label="退款">
                            <el-switch v-model="info.is_refund" :active-value="1" :inactive-value="0"></el-switch>
                        </el-form-item>
                        <el-form-item label="退款备注">
                            <span> {{ info.refund_remarks }}</span>
                        </el-form-item>
                        <el-form-item label="简介">
                            <span> {{ info.introduce }}</span>
                        </el-form-item>
                        <el-form-item label="详情">
                            <el-card class="box-card">
                                <div class="editor-content-see" v-html="info.details_rich_text"></div>
                            </el-card>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                v-if="is_auth('product.commodity.issave') && info.publish_state === 0 && info.state === 1"
                                @click="() => { this.$emit('toedit') }" size="medium" type="primary">编辑
                            </el-button>
                            <el-button
                                v-if="is_auth('product.commodity.upapply') && info.publish_state === 0 && info.state === 1"
                                @click="operation_tip(info.good_uuid, info.title, 'upapply')" type="success"
                                size="medium">申请上架
                            </el-button>
                            <el-button
                                v-if="is_auth('product.commodity.withdraw') && info.publish_state !== 0 && info.state === 1"
                                @click="operation_tip(info.good_uuid, info.title, 'withdraw')" type="warning"
                                size="medium">下架撤回
                            </el-button>

                            <el-button size="medium" @click="isreturn">返回
                            </el-button>

                        </el-form-item>
                    </el-form>
                </el-col>

            </el-row>

        </div>


    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        // Wangeditor,
        Qnupload,
    },
    data() {
        return {
            loading: true,
            info: {},
            good_uuid: '',
            // 规格信息
            sku_data: [],
            // 规格类型 0--> 无规格 1--> 多规格
            sku_type: 0,
            minPrice: 0,
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let good_uuid = this.$route.query.good_uuid
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getinfo(good_uuid)
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({ path: '/product/commodity' })    // 返回列表
                    }
                });

            }
        },
        //商品详情
        getinfo() {
            let postdata = {
                api_name: "product.commodity.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid: this.good_uuid
            }
            console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                console.log('json', json)
                if (json.code === 0) {
                    this.info = json.data
                    this.store_uuid = json.data.store_uuid
                    if (json.data.sku_data) {
                        if (json.data.sku_data.length > 0) {
                            this.sku_data = json.data.sku_data;
                            this.mathMin();
                        }
                    }
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(good_uuid = '', title = '', operation = '') {

            let tip = ''
            let options = {}
            // 上架
            if (operation === 'upapply') {
                tip = '上架【' + title + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '下架【' + title + '】？'
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(good_uuid, operation)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(good_uuid = '', operation = '') {
            let postdata = {
                api_name: "product.commodity." + operation,
                token: this.Tool.get_l_cache('token'),
                good_uuid: good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/product/commodity' })
        },
        // 计算规格中的最小价格
        mathMin() {
            const { sku_data } = this;
            let data = [], priceData = [];
            sku_data.map(item => {
                if (item.sku_state !== 0) {
                    data = data.concat(item);
                }
            });
            data.map(item => {
                if (!this.Tool.is_empty(item.sku_price)) {
                    priceData = priceData.concat(parseFloat(item.sku_price));
                }
            })
            if (priceData.length > 0) {
                const price = Math.min(...priceData);
                this.minPrice = price;
            }
        },
        // 返回是否有设置规格
        returnSkuState() {
            const { sku_data } = this;
            let count = 0;
            if (sku_data.length === 0) {
                return false;
            } else {
                for (let i in sku_data) {
                    if (sku_data[i].sku_state === 0) {
                        count += 1;
                    }
                }
                if (count === sku_data.length) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
